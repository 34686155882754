@import "../../style/Global";

.inputWrapper {
  padding: 5px;
  display: inline-flex;
  flex-wrap: wrap;
  box-sizing: border-box;

  .inputLabel {
    padding: 5px;
    font-size: 14px;
  }

  .inputText {
    width: 100%;
    border-radius: 10px;
    box-shadow: inset 0 0 0 1px #cccccc;
    position: relative;

    input {
      width: 100%;
      border-radius: 10px;
      outline: none;
      border: 0;
      font-size: 16px;
      padding: 15px 15px;
      padding-right: 33px;
      box-sizing: border-box;
      background-color: rgba(0, 0, 0, 0);

      &:disabled {
        color: #737373;
      }
    }

    &.disable {
      background-color: #f3f3f3;
    }

    &.focus {
      box-shadow: inset 0 0 0 2px $defaultColor;
    }

    .inputRemoveIcon {
      position: absolute;
      right: 0;
      margin-top: 12px;
      margin-right: 5px;
      cursor: pointer;
      border-radius: 5px;
      transition: background-color .25s ease;
      color: #e5e5e5;

      &.hide {
        visibility: hidden;
      }

      &.show {
        visibility: visible;
      }

      &:hover {
        color: $colorPrimary;
        background-color: #eaeaea;
      }
    }
  }
}
