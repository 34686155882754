@import "../../style/Global";

.container {
  width: 100%;
  background-color: white;
  min-height: 50px;
  border-radius: 5px;
  box-shadow: 0 0 15px 0 #dddddd;
  place-content: flex-start;
  padding: 10px;
  box-sizing: border-box;

  @include flex(start, start);

  &.flexible {
    flex-wrap: nowrap;
  }

  &:last-child {
    margin-bottom: 0;
  }
}
