@import "../../style/Global";

.modalWrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &.show {
    display: flex;
  }

  &.hide {
    display: none;
  }

  .modalUnderlay {
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
  }

  .modalContent {
    position: relative;
    z-index: 1000;
    width: 500px;
    min-height: 200px;
    margin: 0 auto;
    background-color: white;
    max-height: 85%;
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    box-shadow: 0 0 15px 0 #dddddd;

    & > * {
      display: inline-flex;
      width: 100%;
      padding: 10px;
      box-sizing: border-box;
    }

    .header {
      .title {
        width: 100%;
        padding: 15px;
        font-weight: bold;
      }
    }

    .section {
      padding: 10px 20px;
      flex-grow: 1;
      display: flex;
      overflow-y: auto;
    }

    .footer {
      align-self: flex-end;
      justify-content: flex-end;

      .buttons {
        display: flex;
      }
    }
  }
}
