@import "../../style/Global";

.pageTemplate {
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  padding: 15px;
  overflow-y: auto;

  &.flexible {
    display: flex;
    flex-wrap: nowrap;
  }
}
