@import "../../style/Global";

.inputDateTimeWrapper {
  display: inline-flex;
  padding: 10px 5px;

  .inputDateTime {
    width: 100%;
    border-radius: 4px;
    box-shadow: inset 0 0 0 1px #cccccc;
    outline: none;
    border: 0;
    font: inherit;
    padding: 18px 14px;
    margin: 0;
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0);

    &:disabled {
      color: #737373;
    }

    &:focus {
      box-shadow: inset 0 0 0 2px $defaultColor;
    }
  }
}
