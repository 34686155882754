@import "Global";
@import "HtmlStyle";

.app {
  display: flex;
  flex-wrap: nowrap;
}

.jumbotron { background-color: $colorJumbotron }
.primary { color: $colorPrimary }
.warning { color: $colorWarning }
.danger { color: $colorDanger }
.success { color: $colorSuccess }
.secondary { color: $colorSecondary }
.info { color: $colorInfo }
.white { color: white }
.bg-primary { background-color: $colorPrimary; color: white }
.bg-warning { background-color: $colorWarning }
.bg-danger { background-color: $colorDanger; color: white }
.bg-success { background-color: $colorSuccess }
.bg-secondary { background-color: $colorSecondary }
.bg-info { background-color: $colorInfo }
.bg-white { background-color: white }
.bg-transparency { background: none; color: $defaultColor }
.bg-primary-hover { background-color: #1a80ce }
.bg-warning-hover { background-color: $colorWarning }
.bg-danger-hover { background-color: #8e0000 }
.bg-success-hover { background-color: $colorSuccess }
.bg-secondary-hover { background-color: $colorSecondary }
.bg-info-hover { background-color: $colorInfo }
.bg-white-hover { background-color: #efefef }
.bg-transparency-hover { background-color: grey }

@import "../component/PageTemplate/PageTemplate";
@import "../component/Button/Button";
@import "../component/TextBox/TextBox";
@import "../component/Table/Table";
@import "../component/Table/TableFilter/TableFilter";
@import "../component/Container/Container";
@import "../component/CheckBox/CheckBox";
@import "../component/InputDateTime/InputDateTime";
@import "../component/Modal/Modal";
