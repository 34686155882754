@import "../../../style/Global";

.tableFilterWrapper {
  position: absolute;
  width: min-content;
  background-color: white;
  right: 10px;
  top: 70px;
  box-shadow: 0 0 15px 0 #dddddd;
  z-index: 99999;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  &.show {
    display: flex;
  }

  &.hide {
    display: none;
  }

  .tableFilterHeader {
    width: 100%;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      font-size: 20px;
    }
  }

  .tableFilterContent {
    display: flex;

    .tableFilter {
      display: flex;
      flex-wrap: wrap;
      width: 250px;
      box-sizing: border-box;
      padding: 0 10px;
      margin-bottom: 10px;
      max-height: 500px;

      .tableFilterListTitle {
        display: flex;
        align-items: center;
        width: 100%;

        .tableFilterTitle {
          font-size: 16px;
          padding: 10px 5px;
          font-weight: bold;
        }

        .tableFilterAll {
          margin-left: 10px;
          white-space: nowrap;
          font-size: 14px;
          color: grey;
          cursor: pointer;
        }
      }

      .tableFilterList {
        width: 100%;
        height: 300px;
        overflow-y: scroll;

        .tableFilterListItem {
          padding: 10px;
        }
      }
    }
  }

  .tableFilterButtons {
    padding: 10px;
    align-self: flex-end;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}
