@import "../../style/Global";

.tableWrapper {
  width: 100%;
  height: 100%;
  padding: 0 15px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: auto;

  .tableLoader {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .tableScrollable {
    .table {
      width: 100%;
      font-size: 16px;

      .tableHeaderRow {
        border-bottom: 1px solid #cccccc;

        .tableHeaderColumn {
          padding: 15px 10px;
          vertical-align: middle;
          white-space: nowrap;
        }
      }

      .tableRow {
        border-bottom: 1px solid #cccccc;
        position: relative;

        .tableColumn {
          position: relative;
          padding: 15px 10px;
          vertical-align: middle;
          white-space: nowrap;

          & span {
            display: flex;
            align-items: center;
          }
        }

        &:hover {
          background-color: #efefef;
          cursor: pointer;
        }
      }

      .tableRowAdditional {
        position: absolute;
        width: 100%;
        opacity: 0;
        transition: opacity .25s ease;
        height: 100%;
        @include flex(center, center);

        & > button {
          height: 30px;
          padding: 0 10px;
          @include flex(center, center);

          & > i {
            font-size: 14px;
          }
        }
      }
    }
  }

  .tableBottomRow {
    @include flex(center, flex-end);
    padding: 15px 10px;

    .totalCount {
      margin-top: 3px;
      margin-right: 10px;

      strong {
        font-weight: bold;
      }
    }

    .pageCount {
      margin-top: 3px;
      margin-right: 10px;

      strong {
        font-weight: bold;
      }
    }
  }
}
