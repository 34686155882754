@import "../../style/Global";

.button {
  padding: 10px;
  font-size: 14px;
  border: 0;
  border-radius: 6px;
  margin-right: 5px;
  height: 44px;
  cursor: pointer;
  font-weight: bold;
  @include flex(center, center);

  &.disable {
    opacity: .5;
    cursor: default;
  }

  &:last-child {
    margin-right: 0;
  }

  & > label {
    cursor: pointer;
  }

  & > i + label {
    margin-left: 5px;
  }

  &.dashed {
    border: 1px dashed #cccccc;
  }
}
